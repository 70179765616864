@import "../../../../scss/mix";

.containerXxl {
  @include l-sm {
    padding: 0 !important;
  }
}

.rentItemPage {
  display: flex;
  padding-top: 30px;

  @include l-sm {
    flex-direction: column;
    align-items: flex-start;

    padding: 0;
    padding-bottom: 56px;
  }

  .scroller {
    bottom: 90px !important;
  }
}

.rentItemCarGallery {
  margin-right: 60px;
  overflow-y: scroll;
  max-height: 120vh;
  min-width: 650px;
  &::-webkit-scrollbar {
    width: 0;
  }
  @include l-sm {
    margin-right: 0;
    max-width: 100%;
    min-width: 100px;
  }
}

.rentItemCarInfo {
  display: flex;
  flex-direction: column;
  width: 572px;
  @include l-sm {
    width: 100%;
    padding: 24px 16px;
  }
}

.rentItemCarBrand {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include l-sm {
    font-size: 32px;
    line-height: 38.73px;
    margin-bottom: 8px;
  }
}

.rentItemCarModel {
  color: #bf3535;
}

.rentItemCarRegnum {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #606569;

  margin-bottom: 25px;

  @include l-sm {
    font-size: 16px;
    line-height: 19.36px;
    margin-bottom: 12px;
  }
}

.rentItemCarTagsWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  @include l-sm {
    margin-bottom: 24px;
    gap: 4px;
  }

  &TagStyles {
    font-size: 14px;
    line-height: 17px;
    padding: 4px 8px;
  }
}

.rentItemCarRentPay {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.36px;
  margin-bottom: 30px;
  white-space: pre-wrap;

  @include l-sm {
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 20px;
  }

  div:nth-child(1) {
    margin-bottom: 7px;

    @include l-sm {
      margin-bottom: 8px;
    }
  }

  div:nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 5px;

    @include l-sm {
      font-size: 16px;
      line-height: 19.36px;
      margin-bottom: 4px;
    }

    span {
      font-size: 28px;
      line-height: 33.89px;

      @include l-sm {
        font-size: 24px;
        line-height: 29.05px;
      }
    }
  }

  div:nth-child(3) {
    font-size: 14px;
    color: #606569;
    margin-bottom: 15px;

    @include l-sm {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }

  div:nth-child(4) {
    font-size: 16px;
    font-weight: 500;
    span {
      font-weight: 600;
    }
    @include l-sm {
      font-size: 14px;
    }
  }
}

.rentItemCarDiscountRentPay {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.36px;
  margin-bottom: 30px;

  @include l-sm {
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 20px;
  }

  div:nth-child(1) {
    margin-bottom: 7px;
    color: #008f4b;

    @include l-sm {
      margin-bottom: 8px;
    }
  }

  div:nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
    color: #008f4b;
    p {
      del {
        text-decoration: line-through;
        text-decoration-color: #606569;
      }
      margin: 0;
      color: #606569;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      @include l-sm {
        font-size: 14px;
      }
    }
    @include l-sm {
      font-size: 16px;
      line-height: 19.36px;
      margin-bottom: 4px;
    }

    span {
      font-size: 28px;
      line-height: 33.89px;

      @include l-sm {
        font-size: 24px;
        line-height: 29.05px;
      }
    }
  }

  div:nth-child(3) {
    font-size: 14px;
    color: #606569;
    margin-bottom: 15px;

    @include l-sm {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }

  div:nth-child(4) {
    font-size: 16px;
    font-weight: 500;
    span {
      font-weight: 600;
    }
  }
}

.rentItemCarButtonWrapper {
  margin-bottom: 20px;
  width: 198px;

  button {
    @include l-sm {
      width: 100%;
      max-width: 100%;
      line-height: normal;
    }
  }

  @include l-sm {
    width: 100vw;
    display: flex;
    justify-content: center;
    order: 7;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    padding: 12px 16px 24px;
    left: 0;
    background-color: white;
    box-shadow: 0px -5px 32px 0px #0000000d;
    box-sizing: border-box;
    max-height: 75px;
  }
}

.rentItemCarButtonMobile {
  margin-bottom: 20px;
  width: 198px;

  @include l-sm {
    width: auto;
    order: 7;
    margin-bottom: 0;
  }
}

.tabList {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.callToGetPriceBlock {
  max-width: 372px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  @include l-sm {
    font-size: 12px;
    padding: 0 !important;
  }
  a {
    color: #bf3535;
    font-weight: 500;
    text-decoration: none;
  }
}

.rentCarPriceWarning {
  font-size: 14px;
  color: #606569;
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: #606569;
  }

  @include l-sm {
    font-size: 12px;
    margin-bottom: 12px;
  }
}

.rentItemCarTarrifsTabsWrapper {
  align-self: flex-start;
  margin-bottom: 30px;

  @include l-sm {
    margin-bottom: 20px;
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include l-sm {
      font-size: 18px;
      line-height: 21.78px;
      margin-bottom: 10px;
    }
  }

  div {
    min-width: 275px;
    line-height: 19px;

    @include l-sm {
      padding: 15px 0;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.36px;

      @include l-sm {
        font-size: 14px;
        line-height: 16.94px;
      }
    }

    span:nth-of-type(1) {
      margin-right: 0px;

      @include l-sm {
        margin-right: 0px;
      }
    }

    span:nth-of-type(2) {
      font-weight: 600;
    }

    span:nth-of-type(3) {
      color: #606569;
    }
  }
}

.rentItemCarTarrifsWrapper {
  align-self: flex-start;
  margin-bottom: 30px;

  @include l-sm {
    margin-bottom: 20px;
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 15px;

    @include l-sm {
      font-size: 18px;
      line-height: 21.78px;
      margin-bottom: 4px;
      font-weight: 600;
    }
  }

  div {
    padding: 15px 0;
    min-width: 275px;
    line-height: 19px;

    @include l-sm {
      padding: 12px 0;
    }

    &:not(:last-of-type) {
      border-bottom: 2px solid #f2f3f6;

      @include l-sm {
        border-bottom: 1px solid #f2f3f6;
      }
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.36px;

      @include l-sm {
        font-size: 14px;
        line-height: 16.94px;
      }
    }

    span:nth-of-type(1) {
      margin-right: 0px;

      @include l-sm {
        margin-right: 0px;
      }
    }

    span:nth-of-type(2) {
      font-weight: 600;
    }

    span:nth-of-type(3) {
      color: #606569;
    }
  }
}

.rentItemCarInformationWrapper {
  align-self: flex-start;
  margin-bottom: 40px;

  @include l-sm {
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 15px;

    @include l-sm {
      font-size: 18px;
      line-height: 21.78px;
      font-weight: 600;
    }
  }

  div {
    margin-top: 16px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.2px;
  }

  div p:last-of-type {
    color: #babcbf;
    margin-bottom: 8px;
  }
}

.rentItemCarInfoWrapper {
  margin-top: 45px;
  margin-bottom: 45px;
  align-self: flex-start;
  width: 244px;

  @include l-sm {
    margin-bottom: 30px;
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 15px;

    @include l-sm {
      font-size: 18px;
      line-height: 21.78px;
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    min-width: 244px;

    padding: 12px 0;

    &:not(:last-of-type) {
      border-bottom: 2px solid #f2f3f6;

      @include l-sm {
        border-bottom: 1px solid #f2f3f6;
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.36px;
      &:nth-child(2) {
        text-transform: capitalize;
      }
      @include l-sm {
        font-size: 12px;
        line-height: 16.94px;
      }
    }

    span:nth-of-type(1) {
      width: fit-content;
      margin-right: 16px;
      color: #606569;
    }

    span:nth-of-type(2) {
      justify-self: flex-start;
    }
  }
}

.backButton {
  margin-bottom: 35px;

  @include l-sm {
    display: none;
  }
}
