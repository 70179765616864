$uiRedColor: #ff1f00;
$defaultColor: #222222;

//.form-check{
//  display: flex;
//  padding-left: 5px;
//  align-items: center;
//}
.fade.modal.show {
  /* Ваши стили */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.modal.widened {
  @include xl {
    .modal-dialog {
      --bs-modal-width: 1200px !important;
    }
  }
}
.modal-backdrop.show {
  // opacity: 0.9;
  // backdrop-filter: blur(10px) !important;
}
.toast-header {
  background: $textColor;
  color: white;
  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
  }
}
.toast-body {
  background: rgba($textColor, 0.85);
  color: white;
}
.form-check {
  padding-left: 25px;
  display: block;
  align-content: center;
  gap: 8px;
  min-height: auto;
  max-height: 17px;
  display: flex;
  align-items: center;
  // @include l-md {
  //   display: none;
  // }
  input {
    margin-top: 0;
  }
  label {
    line-height: normal;
    text-align: center;
  }

  margin: 15px 0;

  &:first-child {
    margin: 15px 0 0 0;
  }

  &:last-child {
    margin: 15px 0 0 0;
  }
}

.form-mobile-policy {
  display: none;
  font-size: 10px;

  &-link {
    color: #bf3535 !important;
  }
  @include l-md {
    display: block;
  }
  @include l-sm {
    margin: 0;
  }
}

.form-check-input {
  transition: all 0.15s ease-in-out;
  border-color: $defaultColor;
  margin-left: -25px;
  box-sizing: border-box;
  &:checked {
    background-color: white;
    border-color: $uiRedColor;
  }
  &:focus {
    outline: 0;
    box-shadow: unset;
    border-color: $defaultColor;
  }
  &[type="checkbox"] {
    margin-left: -24px;
    border-radius: 2px;
    border-width: 1px;
    width: 15px;
    box-shadow: 0 0 0px 1px rgba($textColor, 0.7);
    height: 15px;
    transition: all 0.2s ease-in-out;
    //border: unset;
    //background-image: url("./../images/index/checkbox-empty.svg");
    &:hover {
      border-color: $uiRedColor;
      box-shadow: 0 0 0px 1px rgba($uiRedColor, 0.7);
    }
    &:checked {
      border-color: $uiRedColor;
      box-shadow: 0 0 0px 1px rgba($uiRedColor, 0.7);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23FF1F00%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    }
  }
}

@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1432px;
    width: 100%;
    // padding: 0 16px !important;
  }
}

.container-xxl {
  padding: 0 16px !important;
}

.modal-xl {
  --bs-modal-width: 1150px;
  min-width: 1150px;
}

.modal-content {
  border-radius: 3px !important;
}
