.copy-success {
  animation: fadeOut 6s ease-in-out forwards;
  opacity: 1;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.share_modal {
  .modal-dialog {
    max-width: 530px;
  }
}

.share-button {
  &_container {
    max-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &_mobile {
      .share-label {
        display: flex;
        width: fit-content;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap: 10px;
        max-height: 22px;
      }
    }
    &_label {
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 10px;
      max-height: 150px;
      cursor: pointer;
      width: fit-content;
      text-wrap: nowrap;
      .share-label {
        &:hover {
          color: #bf3535;
        }
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 10px;
      }
    }
  }
}
.share-modal_content {
  width: 530px;
  height: 415px;
  padding: 30px 40px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .call-content-input {
    padding: 0;
    width: 450px;
  }
  .site-btn {
    width: 229px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 25px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.share-links {
  display: flex;
  gap: 15px;
}
.share-link {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1.5px solid black;
  box-shadow: 0px 0px 10.51px 0px #0000001a;
  background-color: white;
  border-radius: 100px;

  &:hover {
    opacity: 1;
  }

  .hover-image {
    display: none;
  }
  .default-image {
    // width: 22px;
    height: fit-content;
  }
  &:hover {
    border: 0px solid transparent;
    .default-image {
      display: none;
    }
    .hover-image {
      display: block;
      width: 66px;
      height: 66px;

    }
  }

  &.mobile {
    background-color: transparent;

    .default-image {
      display: block;
    }
  }
}
