@import "../../../scss/mix";

.customCarousel {
  width: 650px;

  @include l-sm {
    width: 100%;
    height: 374px;
  }

  &ImageDoesNotExistsWrapper {
    width: 650px;
    height: 500px;

    @include l-sm {
      width: 100vw;
      height: 300px;
    }
  }

  &Image {
    width: 650px;
    height: 500px;
    max-width: 100%;
    object-fit: cover;
    @include l-sm {
      width: 100vw;
      height: 300px;
    }
  }

  .carousel {
    width: 650px;
    height: 500px;

    @include l-sm {
      width: 100%;
      height: 300px;
      &-item {
        .customCarouselImage {
          object-fit: cover;
          height: 300px;
          width: 100vw !important;
        }
      }
    }

    a {
      top: 50%;
      transform: translateY(-50%);
      margin: 10px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      opacity: 1;
      background-color: #222222cc;

      a:hover {
        opacity: unset;
      }

      span {
        width: 15px;
        opacity: 1;
      }

      @include l-sm {
        width: 32px;
        height: 32px;
      }
    }
  }

  .CarouselBottomLibrary {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
    @include l-sm {
      padding-top: 8px;
      gap: 8px;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    img {
      cursor: pointer;
      width: 100px;
      height: 90px;
      // margin-top: 2%;

      @include l-sm {
        width: 80px;
        height: 66px;
      }
    }
  }
}
