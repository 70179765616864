.gallery-container {
  display: flex;
  align-items: center;
  position: fixed;
  overflow-y: hidden;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  flex-direction: column;
  justify-content: center;

  .carousel {
    position: static;
  }

  .PhotoView-Slider__Backdrop {
    background: transparent;
  }

  .carousel-control-prev {
    background-color: transparent !important;
    position: fixed !important;
    bottom: -5px !important;
    left: 12px !important;
    z-index: 150;
    display: flex;
    color: black;
    top: auto !important;
  }

  .carousel-control-next {
    background-color: transparent !important;
    position: fixed !important;
    bottom: -5px !important;
    right: 12px !important;
    z-index: 150;
    display: flex;
    color: black;
    top: auto !important;
  }

  .carousel-indicators {
    height: 72px;
    background-color: white;
    width: 100vw;
    position: fixed;
    bottom: 16px;
    bottom: 0 !important;
    left: 0 !important;
    align-items: flex-end;
    margin: 0;
    button {
      height: 2px;
      width: 24px;
      border-radius: 2px;
      background-color: #babcbf;
      margin-bottom: 16px;
    }
    button.active {
      background-color: black;
    }
  }

  // Отмена взаимодействия с изображениями в карусели
  .carousel-item {
    // pointer-events: none; // Это отключит все взаимодействия с картинками
  }
}

.customCarouselImage {
  object-fit: contain;
  width: 100%;
  height: auto;
  // pointer-events: auto; // Включаем взаимодействие только для самой картинки (масштабирование и перемещение)
}

.customSlider {
  /* Используем 100dvh для корректной работы на мобильных устройствах */
  max-height: calc(100dvh - 130px);
  transform: translateY(60px);

  .PhotoView__PhotoBox {
      position: relative;
      top: auto; /* Сброс некорректного top */
      bottom: 50px; /* Используем bottom вместо top */
      
      /* Для поддержки безопасной зоны на устройствах с выемками (iPhone) */
      padding-bottom: env(safe-area-inset-bottom, 0px);

      /* Safari-specific fix для устранения багов с позиционированием */
      -webkit-transform: translateZ(0);
  }
}
