@import "../../scss/mix";

.horizontal-slider {
  width: 100%;
  margin: auto;
  height: fit-content;
  // min-height: 26px;

  //@include l-md {
  //  min-height: 12px;
  //
  //}
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #bf3535;
  border: 5px solid #bf3535;
  border-radius: 100%;
  display: block;
  color: transparent;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: #bf3535;
}
.example-track {
  position: relative;
  background: #ddd;
  border-radius: 25px;
}
.example-track.example-track-0 {
  background: #bf3535;
}
.horizontal-slider .example-track {
  top: 10px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 5px;
  width: 14px;
  outline: none;
  height: 14px;
  line-height: 38px;
}

.double-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #bf3535;
  border: 5px solid #bf3535;
  border-radius: 100%;
  display: block;
  color: transparent;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.double-thumb.active {
  background-color: #bf3535;
}
.double-track {
  position: relative;
  background: #ddd;
  border-radius: 25px;
  height: 2px;
}
.double-track.double-track-1 {
  background: #bf3535;
}
.horizontal-slider .double-track {
  top: 12px;
  height: 2px;
}
.horizontal-slider .double-thumb {
  top: 8px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.indexCalculatorSliderLabel {
  font-family: "Inter";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: 300;

  //@include l-sm {
  //  font-size: 16px;
  //}
}
.indexCalculatorSliderValue {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: 0.02em;
}

.calculator-slider {
  // margin: 0 0 30px 0  ;

  // &:last-child {
  //   margin: 0 !important;
  // }
  width: 540px;
  margin: 0 0 30px 0;

  &-value {
    width: 540px;
    margin: 5px 0 0 0;
  }
}

@include l-sm {
  .horizontal-slider {
    min-height: 10px;
  }
  .indexCalculatorSliderLabel {
    font-size: 12px;
  }

  .indexCalculatorSliderValue {
    font-size: 16px;
  }
  .indexCalculatorPeriodIndicator {
    font-size: 20px;
  }

  .calculator-slider {
    // margin: 0 0 18px 0;
    width: 100%;
    margin: 0 0 16px 0;
    // edit than chek disiner
    &-value {
      width: 100%;
      margin: 4px 0 0 0;
    }
  }

  .horizontal-slider .example-track {
    height: 2px;
  }

  .horizontal-slider .example-thumb {
    width: 8px;
    height: 8px;
    top: 6px;
  }
}
