.AppArrow {
  margin: 10px 0;
  position: relative;

  //border: 1px solid #BF3535;
  width: 100%;
  display: flex;
  align-items: center;

  .AppArrowEnd {
    position: absolute;
    top: -6px;
    right: -10px;
    height: 15px;
    width: 15px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
  }

  &.ArrowRed {
    // border: 1.5px solid #BF3535;
    height: 3px;
    background-color: #BF3535;
    box-sizing: border-box;

    .AppArrowEnd {
      background-color: #BF3535;
    }
  }

  &.ArrowGray {
    border: 1.5px solid #606569;

    .AppArrowEnd {
      background-color: #606569;
    }
  }
}