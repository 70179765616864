.index__logo {
  position: relative;
  background-size: cover;
  color: white;
  display: flex;
  //box-shadow: 20px 0px 60px rgba(0, 0, 0, 0.5);
  background-position: center;
  @include l-lg {
    background-position: 60%;
    padding-top: unset;
  }

  @include l-sm {
    min-height: 100vh;
    padding-top: 120px;
  }

  @include l-md {
    background-position: 30%;
  }

  .index__logo-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.24) 92.52%);

    @include l-md {
      background: rgba(0, 0, 0, 0.75);
    }

    z-index: 20;
  }

  .index__logo-content {
    overflow-x: hidden;
    max-width: 100%;
    padding: 190px 0 80px 0;
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    justify-content: center;

    & .indexCalculatorSliderLabel,
    .indexCalculatorPeriodIndicatorComment {
      @include l-md {
        font-size: 10px;
      }
    }

    @include l-md() {
      padding: 100px 0 70px 0;
    }

    @include l-sm() {
      padding: 0;
    }

    .index__logo-header {
      font-weight: 700;
      font-size: 64px;
      line-height: 120%;
      color: white;
      margin: 0 0 90px 0;
      //text-transform: uppercase;
      @include l-sm {
        margin: 0 0 40px 0;
      }
      @include l-xxl {
        font-size: 42px;
        letter-spacing: 1px;
      }
      // @include l-md {
      //   font-size: 48px;
      // }

      .muted {
        font-weight: 400;
        font-size: 56px;
        color: #d9d9d9;
        line-height: 120%;
        margin: 5px 0 0 0;
        @include l-xxl() {
          font-size: 38px;
        }
        @include l-md() {
          font-size: 36px;
          margin: 0;
        }
      }

      & .indexCalculatorPeriodIndicator {
        span {
          font-size: 10px !important;
        }
      }
    }

    @include l-md() {
      padding-top: 80px;
    }

    @include l-sm() {
      padding: 0;
    }
  }

  @include l-md {
    padding-top: calc(57.64px + 64px);
    padding-bottom: 40px;
  }

  .index__logo-car {
    width: 100%;
  }
}

.indexCalculatorPeriodIndicator {
  line-height: 120%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.04em;
  @include l-xl {
    font-size: 26px;
  }
  @include l-md {
    font-size: 22px;
  }

  & > span {
    font-weight: 300;
  }

  .indexCalculatorPeriodIndicatorComment {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.04em;
    @include l-md {
      font-size: 14px;
    }
  }
}

.index__logo-comment {
  color: #f2f2f5;
  font-family: "Inter";
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  max-width: 600px;
  width: 100%;
  margin: 142px 0 0 0;
  @include hd {
    max-width: 900px;
    min-width: 900px;
  }
  @include l-lg() {
    margin-top: 60px;
    font-size: 14px;
    min-width: 328px;
  }
  @include l-sm() {
    margin: 24px 0 0 0;
    font-size: 10px;
    max-width: 328px;
    width: 100%;
  }
}

.index__steps-desktop {
  display: block;
  padding: 160px 0 100px;
  @include media-breakpoint-down(md) {
    padding: 50px 0 30px;
    display: none;
  }
}

.index__steps-mobile {
  padding: 40px 0 78px;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
  @include l-sm {
    padding-top: 0;
  }
}

.index__step {
  position: relative;
  z-index: 30;
  background: transparent;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 20px;
  //max-width: 350px;
  @include l-xl {
    min-height: 280px;
  }
  @include l-md {
    //padding-left: 20px;
    min-height: 200px;
  }

  .index__step-bg {
    font-family: "Lato";
    position: absolute !important;
    bottom: 0;
    left: 120px;
    line-height: 100%;
    font-size: 220px;
    height: 220px;
    font-weight: bold;
    color: #e7e7e8;
    z-index: 20 !important;
    @include l-xl {
      font-size: 160px;
      height: 160px;
    }
    @include l-md {
      font-size: 120px;
      height: 120px;
    }
  }

  .index__step-header {
    position: relative;
    z-index: 30;
    font-family: "Inter";
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    * {
      z-index: 30;
      position: relative;
    }

    @include l-xl {
      font-size: 36px;
    }
    @include l-sm {
      font-size: 28px;
    }
  }

  .index__step-arrow {
    margin: 10px 0;
    position: relative;

    border: 1.5px solid #bf3535;
    width: 100%;

    .index__end-of-arrow {
      position: absolute;
      top: -8px;
      right: -10px;
      height: 15px;
      width: 15px;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
      background-color: #bf3535;
    }
  }

  .index__step-text {
    position: relative;
    z-index: 30;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
  }
}

.index__step-mobile {
  padding: 88px 0 0 0;
  position: relative;

  &-desc {
    & > h2 {
      width: 120px;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }

    & > p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &_bgtext {
    font-family: "Lato";
    position: absolute !important;
    top: 38px;
    right: 0;
    line-height: 100%;
    font-size: 100px;
    height: 120px;
    font-weight: bold;
    color: #e7e7e8;
  }

  &_arrow {
    position: absolute;
    left: 0;
    bottom: -76px;
    z-index: 20;
  }
}

.index__about {
  background: #ffffff;
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 800px;
  padding: 120px 0 140px;
  overflow-x: hidden;

  * {
    position: relative;
    z-index: 30;
  }

  @include l-lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 80px 0 68px 0;
  }
  @include l-md {
    grid-template-columns: 1fr;
    padding: 40px 0 36px 0;
  }

  @include l-sm {
    box-shadow: inset 0px 0px 80px rgba(0, 0, 0, 0.1);
  }
}

.index__about-bg {
  position: absolute !important;
  z-index: 20 !important;
  top: 0;
  //left: calc(70% - 1400px);
  height: 100%;
  background-size: cover;
  //background-position: top;
  width: 100% !important;
  //max-width: 60%;
  clip-path: polygon(0 0, 73% 0%, 27% 100%, 0% 100%);
  background: url("./../../../images/index/about_bg.webp");
  background-size: cover;

  @include l-lg {
    clip-path: unset;
  }

  @include l-md {
    height: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%);
    background: rgba(0, 0, 0, 0.8);
  }

  @include l-sm {
    height: 529px;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
    background-position: 25% 0% !important;
    background:
      // rgba(0, 0, 0, 0.8)
      linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("./../../../images/index/logo-bg.jpg");
  }
}

.index__about-header {
  font-family: "Inter";
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 60px;
  @include l-xl {
    font-size: 48px;
  }
  @include l-md {
    font-size: 32px;

    margin-bottom: 30px;
  }
}

.index__about-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #ffffff;
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  @include l-md() {
    font-size: 16px;

    &:last-child {
      max-width: 315px !important;
    }

    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @include l-sm() {
    font-size: 12px;
    // margin-bottom: 8px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.filter-block-input {
  width: 120px;
  font-size: 14px !important;
}

.index__about-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 80px;
  margin-top: 80px;
  flex-direction: row;
  @include l-md {
    margin-top: 40px;
    margin-bottom: 100px;
    gap: 0;
    flex-direction: column-reverse;
  }

  @include l-sm {
    margin: 40px 0 80px 0;
  }
}

.index__about-label {
  display: block;
  gap: 0;
  @include l-md() {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 8px;
    &:not(:first-child) {
      margin: 0 0 24px 0;
    }
  }
  &-big {
    font-family: "Inter";
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;

    @include l-sm() {
      font-size: 28px;
    }
  }

  &-small {
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;

    @include l-sm() {
      font-size: 12px;
      margin: 0 0 4px 0;
    }
  }
}

.index__about-badges {
  display: block;
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @include l-lg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 10px;
  }
  @include l-md {
    padding: 0 10px;
    margin-top: 20px;
  }
  @include l-sm {
    padding: 0 10px;
    margin-top: 0;
  }
}

.index__about-badge {
  position: absolute;
  display: flex;
  gap: 40px;

  @include l-xs {
    gap: 20px;
  }

  &.index-1 {
    left: calc(67% - 93px);
    top: 120px;
    max-width: 500px;
  }

  &.index-2 {
    left: calc(58% - 122px);
    top: 326px;
    max-width: 592px;
  }

  &.index-3 {
    left: calc(48.7% - 127px);
    top: 554px;
    max-width: 580px;
  }

  &.index-4 {
    left: calc(41% - 160px);
    top: 762px;
    max-width: 520px;
    width: 100%;
  }

  @include l-xl {
    // &.index-1,
    // &.index-2,
    // &.index-3,
    // &.index-4 {
    //   max-width: 40%;
    // }
  }
  @include l-lg {
    color: white;
    position: relative;
    &.index-1,
    &.index-2,
    &.index-3,
    &.index-4 {
      max-width: 100%;
      left: unset;
      top: unset;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @include l-md {
        &:not(:last-child) {
          margin-bottom: 44px;
        }
      }
    }
  }

  .index__about-badge-image {
    min-width: 110px;
    min-height: 110px;
    width: 110px;
    height: 110px;
    background: #ffffff;
    border: 2px solid #f2f3f6;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 66px;
      height: 66px;
      object-fit: contain;
    }

    @include l-lg {
      min-width: 60px;
      min-height: 60px;
      width: 60px;
      height: 60px;
      border: 0.983px solid var(--BG, #f2f3f6);
      background: var(--default-white, #fff);
      box-shadow: 0px 0px 9.834px 0px rgba(0, 0, 0, 0.15);
      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .index__about-badge-header {
    font-family: "Inter";
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include l-lg {
      color: white;
    }

    @include l-md {
      font-size: 24px;
      color: #222222;
    }

    @include l-sm {
      font-size: 20px;
    }
  }

  .index__about-badge-divider {
    // style={{width: '60px', height: '6px'}}
    margin: 16px 0 14px 0;
    width: 60px;
    height: 6px;

    @include l-sm {
      margin: 8px 0 6px 0;
      width: 40px;
      height: 4px;
    }
  }
  .index__about-badge-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
    @include l-xl {
      font-size: 14px;
    }
    @include l-lg {
      color: #dadada;
    }

    @include l-md {
      font-size: 12px;
      color: #222222;
    }

    @include l-sm {
      font-size: 12px;
    }
  }
}

.index__offers {
  padding-top: 120px;
  padding-bottom: 120px;

  @include l-md {
    padding: 40px 0 48px 0;
  }

  .index__offers-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: "Inter";
    font-weight: 600;
    font-size: 62px;
    line-height: 120%;
    margin-bottom: 60px;
    align-items: flex-end;

    .index__offers-header-link {
      font-family: "Inter";
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: 0.01em;
      text-align: end;
      text-transform: uppercase;
      color: #606569;
      position: relative;
      text-decoration: none;
      padding-bottom: 12px;
      transition: all 0.15s ease-in-out;

      &:hover,
      &:active {
        color: $redColor;
        text-decoration: none;
      }

      &::after {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 12px;
        background-image: url("./../../../images/index/gray-arrow.svg");
        content: "";
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:hover::after {
        //background-image: url("./../../../images/index/dark-arrow.svg");
        background-image: url("./../../../images/index/red-arrow.svg");
      }
    }

    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;
    @include l-xl {
      font-size: 52px;
    }
    @include l-lg {
      font-size: 48px;
    }
    @include l-md {
      font-size: 34px;
      margin-bottom: 30px;
    }
  }

  .index-offers-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    @include l-xl {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include l-lg {
      grid-template-columns: 1fr 1fr;
    }
    @include l-sm {
      grid-template-columns: 1fr;
    }
  }

  .index-offers-brands {
    margin-top: 100px;
    display: flex;
    gap: 120px;
    overflow-x: scroll;
    padding-bottom: 25px;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */

    scrollbar-width: auto;
    scrollbar-color: #5c5c5c #ffffff;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ededed;
      border-radius: 6px;
      width: 5px !important;
      border: 0px solid #ffffff;
    }

    @include l-md {
      margin-top: 50px;
      column-gap: 40px;
    }
  }

  @include l-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include l-sm {
    padding: 40px 0 48px 0;
  }
}

.index-offers-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 16px;

  img {
    height: 80px;
    // max-width: 200px;
    width: 100%;
    object-fit: contain;

    @include l-md {
      height: 46px;
    }

    @include l-sm {
      height: 32px;
    }
  }
  .kia-brand {
    height: 34px !important;
  }
}

.great-deal {
  padding: 100px 0 0 0;
  .tag {
    font-size: 14px;
  }
  .site-btn {
    max-width: none;
    max-height: none;
  }

  &_header {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
    margin: 0 0 62px 0;
    & > h1 {
      font-size: 64px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
    }
  }

  @include l-lg {
    &_header {
      display: block;
      margin-bottom: 46px;
      & > h1 {
        font-size: 48px;
      }
    }
  }

  @include l-md {
    &_header {
      display: block;
      margin-bottom: 32px;
      & > h1 {
        font-size: 28px;
      }
    }
  }
  .car__card-description {
    padding-bottom: 25px !important;
  }

  @include l-sm {
    &_header {
      margin: 0 0 26px 0;
      & > h1 {
        font-size: 20px;
      }
    }
    .site-btn {
      max-height: 31px !important;
      padding: 8px 16px !important;
      font-size: 12px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .col-3 {
      padding: 0 !important;
      width: 240px !important;
    }
    .car__card {
      width: 240px !important;
      height: 457px !important;
      max-height: 457px !important;
    }
    .car__card-title {
      font-size: 24px !important;
      margin-bottom: 8px !important;
    }
    .car__card-regnum {
      font-size: 12px !important;
      margin-bottom: 12px !important;
    }
    .car__card-price {
      font-size: 12px !important;
      margin-bottom: 16px !important;
    }
    .car__card-payment {
      margin-bottom: 8px !important;
    }
    .car__card-devider {
      margin-bottom: 12px !important;
    }
    .car__card-description {
      padding: 16px !important;
    }
    .car__card-taglist {
      margin-bottom: 16px !important;
    }
    .tag {
      height: 20px !important;
      font-size: 10px !important;
    }
  }

  &_more {
    margin: 8px 0 0 0;
    cursor: pointer;
    display: block;

    & > p {
      margin: 0 0 0 0;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.18px;
      line-height: normal;
      text-transform: uppercase;
      // transform: translateY(2px);
      color: #606569;

      @include l-md {
        color: #222222;
      }
    }

    & > svg {
      width: 222px;
    }

    img {
      width: 224px;
      height: auto;
      transform: translateY(1px);

      @include l-sm {
        width: 134px;
      }
    }

    &-gray {
      display: block;

      @include l-md {
        display: none;
      }
    }

    &-black {
      display: none;

      @include l-md {
        display: block;
      }
    }

    &:hover {
      @include l-md {
        pointer-events: none;
      }
      .great-deal_more-gray {
        display: none !important;
      }

      .great-deal_more-black {
        display: block !important;
      }

      p {
        color: #222222;
      }
    }
  }

  &_cars {
    min-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-container {
      padding: 20px;
      margin: -20px;
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      scrollbar-width: none; /* for Firefox */
      .car__card {
        width: 95%;
        height: 604px;
      }
      .car__card-description {
        height: 324px;
        padding: 20px;
      }
      .car__card-description-mobile {
        height: 324px;
        padding: 15px 20px 20px 20px;
      }
      .car__card-taglist {
        margin-bottom: 20px;
      }
      .car__card-title {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 500;
      }
      .car__card-regnum {
        font-size: 16px;
      }
      .tag {
        font-size: 14px;
        padding: 5px 15px;
      }
      .site-btn {
        height: 52px !important;
        font-size: 16px !important;
        @include l-sm {
          height: 31px !important;
          font-size: 12px !important;
          display: flex;
          align-items: center;
        }
      }
      .car__card-image,
      .car__card-image-mobile,
      .car__card--car-does-not-exists-wrapper,
      .ImageDoesNotExists_carImageDoesNotExists,
      .car__card-image-mobile {
        height: 280px;
        @include l-sm {
          max-height: 208px;
        }
      }
      .car__card--car-does-not-exists-wrapper {
        div:first-child {
          height: 280px;
        }
      }
      .car__card-title-mobile {
      }
    }
    @include l-sm {
      padding-left: 16px;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}

@include l-lg {
  .great-deal {
    padding: 64px 0 0 0;
  }
}

@include l-md {
  .great-deal {
    padding: 48px 0 0 0;
  }
}

.catalog {
  padding: 40px 0 0 0;

  &-alert {
    margin: 0 0 60px 0;

    @include l-md {
      margin: 0 0 24px 0;
    }

    &_message {
      font-size: 14px;
      margin: 0;

      span {
        color: #bf3535;
        cursor: pointer;
      }
      line-height: normal;
      @include l-md {
        font-size: 10px;
      }
    }
  }
  @include l-sm {
    padding: 24px 0 0 0;
  }
}

@media (max-width: 900px) {
  .great-deal_more {
    display: flex !important;
    align-items: center;
    & > p {
      // transform: translateY(0);
      font-size: 16px;
      margin: 0 8px 0 0;
    }

    & > img {
      width: 134px;
      // fill: #606569;
    }
  }
}
@media (max-width: 700px) {
  .great-deal_more {
    display: flex !important;
    align-items: center;
    & > p {
      // transform: translateY(0);
      font-size: 14px;
      margin: 0 8px 0 0;
    }

    & > img {
      width: 134px;
      // fill: #606569;
    }
  }
}

@media (max-width: 500px) {
  .great-deal_more {
    display: flex !important;
    align-items: center;
    & > p {
      // transform: translateY(0);
      font-size: 12px;
      margin: 0 16px 0 0;
    }

    & > img {
      width: 134px;
      // fill: #606569;
    }
  }
}

.service__logo {
  max-height: 1920px;
  height: 1000px;
  position: relative;
  background-size: cover;
  color: white;
  display: flex;
  box-sizing: border-box;
  //box-shadow: 20px 0px 60px rgba(0, 0, 0, 0.5);
  background-position: center;
  @include l-lg {
    background-position: 60%;
    padding-top: unset;
  }

  @include l-md {
    background-position: 30%;
    height: 100vh;
  }

  &-comment {
    @include l-sm {
      font-weight: 400;
    }
  }

  .service__logo-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.24) 92.52%);

    @include l-md {
      background: rgba(0, 0, 0, 0.75);
    }

    z-index: 20;
  }

  .service__logo-content {
    overflow-x: hidden;
    max-width: 100%;
    padding: 180px 0 50px 0;
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    justify-content: center;

    button {
      max-height: 49px;
      height: 49px;
      font-size: 16px;
      font-weight: 600;
      padding: 15px 25px;
      display: flex;
      align-items: center;
      @include l-sm {
        max-height: 39px;
        height: 39px;
        font-size: 12px;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        .order-call_button {
          font-size: 12px !important;
        }
        svg {
          margin-left: 4px;
          height: 17px;
          width: 17px;
        }
      }
    }

    & .indexCalculatorSliderLabel,
    .indexCalculatorPeriodIndicatorComment {
      @include l-md {
        font-size: 10px;
      }
    }

    @include l-md() {
      padding: 100px 0 70px 0;
      font-size: 12px !important;
    }

    @include l-sm() {
      padding: 0;
      font-size: 12px !important;
    }

    .service__logo-header {
      font-weight: 700;
      font-size: 64px;
      line-height: 120%;
      color: white;
      margin: 0 0 30px 0;
      //text-transform: uppercase;
      @include l-xxl {
        font-size: 40px;
        letter-spacing: 1px;
      }
      @include l-sm {
        margin: 0 0 16px 0;
      }

      .muted {
        font-weight: 400;
        font-size: 56px;
        color: #d9d9d9;
        line-height: 120%;
        margin: 5px 0 0 0;
        @include l-xxl() {
          font-size: 38px;
        }
        @include l-md() {
          font-size: 36px;
          margin: 0;
        }
      }

      & .indexCalculatorPeriodIndicator {
        span {
          font-size: 10px !important;
        }
      }
    }

    @include l-md() {
      padding-top: 80px;
    }

    @include l-sm() {
      padding: 0;
    }
  }

  @include l-md {
    padding-top: calc(64px + 58px);
    padding-bottom: 40px;
  }

  .service__logo-car {
    width: 100%;
  }

  .service__logo-content_add {
    font-size: 20px;
    @include l-md() {
      font-size: 16px !important;
    }

    @include l-sm() {
      padding: 0;
      font-size: 16px !important;
    }
  }
}

.custom-backdrop {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  --bs-backdrop-opacity: 0.95;
  background-color: rgba(0, 0, 0, 0.95);
}
