.tag {
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  background: #e7e7e8;
  color: #222222;
  max-height: 27px;


  & .tag-with-adaptive {
    @include l-md {
      font-size: 14px;
      padding: 2px 6px;
    }
    @include l-sm {
      padding: 2px 6px;
      font-size: 10px;
    }
  }

  &.free {
    background: rgba(64, 129, 4, 0.2);
  }
  &.not-free {
    background: rgba(129, 4, 4, 0.2);
  }
}

.tag-with-adaptive {
  @include l-md {
    font-size: 14px;
    padding: 2px 6px;
  }
  @include l-sm {
    padding: 2px 6px;
    font-size: 10px;
  }
}

.tag-without-background {
  @include l-sm {
    color: $grayColor;
    background: none;
    padding: 0;
    font-size: 8px;
    font-weight: 400;
    line-height: 7.26px;

    &:after {
      content: "/";
      margin-left: 6px;
    }

    &:last-of-type:after {
      content: " ";
      margin-left: 0;
    }
  }
}
