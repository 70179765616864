// .filter-block-container {
//   width: 100%;
//   max-height: calc(100vh - 120px);
//   overflow-y: scroll;
//   position: sticky;
//   top: 120px;
//   //padding-left: 10px;
//   z-index: 90;
//   &::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//   }
//   &::-webkit-scrollbar-button {
//     width: 6px;
//     height: 6px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #e7e7e8;
//     border: 0px none #ffffff;
//     border-radius: 2px;
//   }
//   &::-webkit-scrollbar-thumb:hover {
//     background: #e7e7e8;
//   }
//   &::-webkit-scrollbar-thumb:active {
//     background: #e7e7e8;
//   }
//   &::-webkit-scrollbar-track {
//     background: #ffffff;
//     border: 0px none #ffffff;
//     border-radius: 1px;
//   }
//   &::-webkit-scrollbar-track:hover {
//     background: #ffffff;
//   }
//   &::-webkit-scrollbar-track:active {
//     background: #ffffff;
//   }
//   &::-webkit-scrollbar-corner {
//     background: transparent;
//   }
// }

.filter-menu {
  position: fixed;
  top: 0;
  right: -150%;
  z-index: 202;
  width: 100vw;
  height: 100vh;
  box-shadow: inset 0px 0px 80px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }

  &.active {
    right: 0;
  }

  &_scroller {
    height: auto;
    &::-webkit-scrollbar {
      display: none;
      background-color: transparent;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 203;
    box-shadow: 0px 5px 32px 0px #0000000d;
    background-color: #ffffff;
    padding: 16px 20px;
  }

  &_body {
    padding: 16px;

    // overflow: scroll;

    &-content {
      // height: 1860px;
    }
  }
}
.filters-block-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.filters-block {
  display: block;
  background-color: transparent;
  z-index: 90;
  padding-right: 21px;
  padding-bottom: 20px;

  @include l-lg {
    display: none;
  }

  .filters-block-open-btn {
    display: none;
    width: 42px;
    height: 42px;
    position: absolute;
    right: 0;
    top: 15%;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    z-index: 100;
    background: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #a5a5a5;
    box-shadow: 0 0.5rem 0.2rem rgba(0, 0, 0, 0.15) !important;
    border-left: unset;
    outline: none;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      transition: all 0.4s ease-in-out;
    }
  }
  transition: all 0.4s ease-in-out;

  @include l-md {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    position: fixed;
    z-index: 90;
    width: 80%;
    height: 100vh;
    max-height: 100vh;
    left: -80%;
    top: 0;
    background: white;
    .filters-block-content {
      padding: 40px 10px 20px 10px;
      overflow-y: scroll;
      min-height: 100%;
      width: 100%;
      z-index: 90;
      position: relative;
    }
    &.open {
      left: 0;
      .filters-block-open-btn img {
        transform: rotate(180deg);
      }
    }
    .filters-block-open-btn {
      display: flex;
    }
  }
}

.catalog__grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 100vh;

  @include l-md {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }

  @include l-sm {
    grid-template-columns: repeat(2, 1fr);

    grid-gap: 6px;
  }

  &-paginator {
    margin: 30px 0 0 0;

    @include l-md {
      margin: 38px 0 0 0;
    }

    @include l-sm {
      margin: 16px 0 0 0;
    }
  }
}

.catalog__filter-icon {
  @include l-sm {
    img {
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }
  }
}

.catalog__filter-btn {
  background: $textColor;
  border-radius: 2px;
  padding: 5px 10px;
  color: white;
  border: $textColor 1.5px solid;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;

  @include l-sm {
    font-size: 10px;
    max-height: 20px;
    padding: 4px 8px;
    border: $textColor 1px solid;
  }
  &.selected {
    font-weight: 500;
    border-color: $redColor;
    background: white;
    color: $redColor;
  }
}

.rent-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 15px;
  @include l-xxl {
    grid-template-columns: repeat(3, 1fr);
  }
  @include l-md {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 8px;
    column-gap: 6px;
  }
}

.filter__block {
  // margin-bottom: 20px;

  // @include l-md {
  //   // border-bottom: 1px solid #f2f3f6;
  //   margin: 0 0 15px 0;
  // }
  .double-track {
    top: 0 !important;
  }
  .double-thumb {
    top: -4px !important;
  }

  button {
    background: $textColor;
    border-radius: 2px;
    padding: 5px 10px;
    color: white;
    height: 27px;
    max-height: 17px;
    border: $textColor 1.5px solid;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
  }

  // padding: 15px 0;

  @include l-sm {
    padding-bottom: 8px;

    border-bottom: 1px solid #f2f3f6;
  }

  // padding: 0 0 15px 0;

  &.small {
    // padding: 8px 0;
    padding-top: 15px;
    border: none;
    .filter__block-header {
      font-size: 14px;
      //color: #797979;
      //padding-left: 5px;
    }
    @include l-sm {
      padding-top: 8px;
    }
  }
  .filter__block-header {
    margin-top: 15px;
    padding-left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    outline: none;
    background: transparent;
    color: $textColor;
    border: none;
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;

    @include l-sm {
      font-size: 18px;
      line-height: normal;
    }
    .arrow {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
      height: 22px;
      width: 22px;
      object-fit: fill;
    }
    &.open {
      min-height: 24px;
      margin-bottom: 15px;
      padding: 0 !important;
      line-height: normal;
      @include l-sm {
        margin-bottom: 0;
      }
      .arrow {
        transform: rotate(0);
      }
    }
  }
  .collapse {
    .catalog__filter-btn {
      @include l-sm {
        line-height: 17px;
        max-height: 24px;
      }
    }
    button.small-black-btn {
      max-height: 24px;
    }
    .filter__block-header {
      min-height: 17px !important;
      margin-bottom: 0px !important;
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
